import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS A VA LOAN?",
      answer: (
        <>
          <p className="mb-0">
            A VA loan is available to military service members, veterans, and
            their surviving spouses. It’s a program provided by the U.S.
            Department of Veterans Affairs, which sets the standards and terms,
            and backs the loan. However, they do not provide the financing.
            Banks and private lenders do.
          </p>
        </>
      ),
    },
    {
      question: "HOW DO I APPLY FOR A VA LOAN?",
      answer: (
        <>
          <p className="mb-0">To apply:</p>
          <ul className="list-disc pl-6">
            <li>Complete our simple VA Loan Qualifier</li>
            <li>We’ll show you options based on your situation and goals</li>
            <li>We’ll help you compare mortgage interest rates and terms</li>
            <li>You’ll choose the offer that best fits your needs</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHO QUALIFIES FOR A VA LOAN?",
      answer: (
        <>
          <p>
            VA loans are provided to qualified active military members,
            veterans, reservists, and surviving spouses.
          </p>
        </>
      ),
    },
    {
      question: "WHAT TYPES OF LOANS ARE THERE?",
      answer: (
        <>
          <p className="mb-0">
            There are several types of VA home loans, including:
          </p>
          <ul className="list-disc pl-6">
            <li>Purchase Loan</li>
            <li>Refinance </li>
            <li>VA Streamline</li>
            <li>VA Jumbo</li>
            <li>Native American Direct Loan (NADL)</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT DOCUMENTS DO I NEED TO QUALIFY FOR A VA LOAN?",
      answer: (
        <>
          <p>
            First, all applicants will need to provide their lender with a
            Certificate of Eligibility (COE). This document shows you meet the
            requirements to obtain a VA loan.
          </p>
          <p>
            Active military members must also provide a Statement of Service,
            which verifies current or past employment in the military.
          </p>
          <p>
            Veterans must also provide a DD Form 214, which is the Certificate
            of Release or Discharge from Active Duty.
          </p>
          <p className="mb-0">
            Surviving spouses must provide only the Certificate of Eligibility
            and one of the following must be true:
          </p>
          <ul className="list-disc pl-6">
            <li>Their veteran spouse died while serving</li>
            <li>
              Their veteran spouse died from a service-connected disability
            </li>
            <li>Their veteran spouse is missing in action</li>
            <li>Their veteran spouse is a prisoner of war (POW)</li>
          </ul>
        </>
      ),
    },
    {
      question: "HOW DO I GET A CERTIFICATE OF ELIGIBILITY?",
      answer: (
        <>
          <p>
            Visit the U.S. Department of Veterans Affairs Certificate of
            Eligibility page for instructions on requesting a COE.
          </p>
        </>
      ),
    },
    {
      question: "CAN MY LENDER GET MY CERTIFICATE OF ELIGIBILITY FOR ME?",
      answer: (
        <>
          <p>
            Yes, you can ask your lender to obtain your Certificate of
            Eligibility on your behalf.
          </p>
        </>
      ),
    },
    {
      question: "CAN I GET AN FHA LOAN IF I HAVE STUDENT LOAN DEBT?",
      answer: (
        <>
          <p>
            Yes, you can. Your student loan debt is, however, included in your
            monthly debts, and if it pushes your debt-to-income ratio too high,
            you might not qualify for a mortgage.
          </p>
        </>
      ),
    },
    {
      question: "HOW MANY TIMES CAN I USE A VA LOAN?",
      answer: (
        <>
          <p>
            VA loans are a lifetime benefit and can be used as many times as you
            like.
          </p>
        </>
      ),
    },
    {
      question: "WHEN DOES THE VA FUNDING FEE APPLY?",
      answer: (
        <>
          <p>
            All VA loans include a VA funding fee, which is due at closing. Your
            lender sends the funds to the U.S. Department of Veterans Affairs,
            which they use to fund the program
          </p>
          <p className="mb-0">There is an exemption for:</p>
          <ul className="list-disc pl-6">
            <li>Veterans with a disability rating over 10%</li>
            <li>Purple Heart recipients</li>
            <li>Surviving spouses of veterans who died in the line of duty</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT ARE THE VA LOAN LIMITS?",
      answer: (
        <>
          <p>There is no loan limit for VA loans.</p>
        </>
      ),
    },
    {
      question: "DO ALL LENDERS OFFER VA HOME LOANS?",
      answer: (
        <>
          <p>No, not all lenders participate in the VA home loan program.</p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="VA Loans | Home Loans | Accel | Pasadena Mortgage Brokers"
        description="If you’re a U.S. military service member, veteran, or surviving spouse, a VA loan has some excellent perks that can make it easy for you to buy your dream home."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>VA Loan: For Our Military</h1>
              <p>
                If you’re a U.S. military service member, veteran, or surviving
                spouse, a VA loan has some excellent perks that can make it easy
                for you to buy your dream home in Pasadena, Los Angeles County,
                or anywhere else in California.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 FHA Loans/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>VA Home Loans Have Excellent Benefits</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">No Down Payment</h3>
                <p className="mb-0">
                  That’s right—you can buy a home with no money down when
                  utilizing your VA loan benefits.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">PMI Not Required</h3>
                <p className="mb-0">
                  Private mortgage insurance is not required for VA loans, which
                  can save you thousands.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">
                  Competitive Interest Rates
                </h3>
                <p className="mb-0">
                  You can lock in a great interest rate—VA loans offer some of
                  the best rates in the market.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">Lifetime Benefit</h3>
                <p className="mb-0">
                  You can use your VA home loan benefits throughout your
                  lifetime, as many times as you like.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Who’s Eligible?</h2>
              <p>
                VA loans are designed to benefit active duty service members,
                veterans, and surviving spouses. To qualify, you must meet one
                of these requirements:
              </p>
              <ul className="styled-list-arrow">
                <li>
                  You served at least 90 consecutive days of active service
                  during wartime or 181 days of active service during peacetime
                </li>
                <li>
                  You served in the National Guard or Reserves for at least six
                  years
                </li>
                <li>
                  You’re a surviving spouse of a service member who died in the
                  line of duty or from a service-related disability
                </li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/2.0 Conventional Loans/Who’s Eligible.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
